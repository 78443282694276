




























import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import MediaCommissionItem from '@/components/media/CommissionItem.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  find: (b: string, c: string) => ApiResource.Commission,
  listItems: (c: string) => ApiResource.CommissionItem[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    MediaCommissionItem,
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      find: 'business/commission/Find',
      listItems: 'business/commission/item/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    business(): ApiResource.Business {
      // Fallback to finding by ID automatically
      return this.detect(this.VANITY);
    },
    commission(): ApiResource.Commission {
      return this.find(this.business.id, this.COMMISSION_ID);
    },
    items(): ApiResource.CommissionItem[] {
      return this.listItems(this.COMMISSION_ID)
        .sort((
          a: ApiResource.CommissionItem,
          b: ApiResource.CommissionItem,
        ) => b.created_at - a.created_at);
    },
  },
});
