var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('ErrorMessage',{attrs:{"error":_vm.error}}),(_vm.item)?_c('MediaCommissionItem',{attrs:{"item":_vm.item,"is-business":""},scopedSlots:_vm._u([{key:"purpose",fn:function(){return [_c('h2',{staticClass:"font-semibold text-2xl mb-3",domProps:{"textContent":_vm._s(_vm.$t('title', { purpose: _vm.item.purpose }))}})]},proxy:true},{key:"action-button",fn:function(){return [(_vm.item.is_paid && !_vm.item.is_refunded && !_vm.item.is_forgiven)?_c('ItemRefundButton',{staticClass:"is-fullwidth",attrs:{"item-id":_vm.item.id,"business-id":_vm.BUSINESS.id,"commission-id":_vm.COMMISSION_ID},on:{"item-refund-error":_vm.handleError}}):_vm._e(),(_vm.item.is_paid && _vm.item.is_refunded && !_vm.item.is_forgiven)?_c('ItemForgiveButton',{staticClass:"is-fullwidth",attrs:{"item-id":_vm.item.id,"business-id":_vm.BUSINESS.id,"commission-id":_vm.COMMISSION_ID},on:{"item-forgive-error":_vm.handleError}}):_vm._e()]},proxy:true}],null,false,3083386769)},_vm._l((_vm.files),function(file){return _c('MediaCommissionFile',{key:file.id,attrs:{"file":file,"is-owner":file.user_id === _vm.currentUserId},scopedSlots:_vm._u([{key:"file-name",fn:function(){return [_c('RouterLink',{staticClass:"font-bold",attrs:{"to":{
            name: 'dashboard.commission.file',
            params: {
              VANITY: _vm.VANITY,
              COMMISSION_ID: _vm.COMMISSION_ID,
              FILE_ID: file.id,
            }
          }},domProps:{"textContent":_vm._s(file.name)}})]},proxy:true},{key:"download-button",fn:function(){return [_c('FileDownloadButton',{staticClass:"is-medium is-link",attrs:{"file-data":file,"commission-id":_vm.COMMISSION_ID,"business-id":_vm.BUSINESS.id,"on-success-open":""},on:{"file-download-error":_vm.handleError}})]},proxy:true},{key:"delete-button",fn:function(){return [_c('FileDeleteButton',{attrs:{"business-id":_vm.BUSINESS.id,"commission-id":_vm.COMMISSION_ID,"file-id":file.id},on:{"file-delete-error":_vm.handleError}})]},proxy:true}],null,true)})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }